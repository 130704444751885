"use client";

// *** Module *** //

import { signOut, useSession } from "next-auth/react";
import { Menu, X } from "lucide-react";
import { useState } from "react";
import Image from "next/image";
import Link from "next/link";

import React from "react";

// *** My Module *** //

import { Dialog, DialogPanel } from "@headlessui/react";
import LogoPic from "@/public/logo.png";
import useLang from "@/hooks/useLang";

import { Prisma } from "@prisma/client";
import { usePathname, useRouter } from "next/navigation";
import useFncAction from "@/hooks/useFncAction";
import { ChangecompanyAction } from "./action/change_company.action";
import { toast } from "sonner";
import { TLangConfig, TNavigation } from "./_hec/navigation";
import { Button } from "@/components/ui/button";

// ??? Interface ??? //

type ICompanys = Prisma.companiesGetPayload<{
  select: {
    id: true;
    name: true;
  };
}>[];

/**
 * Renders the header component.
 * @returns The header component.
 */
export default function Header({
  companys,
  lang_config,
  navigation,
  need_to_be_connect = true,
}: {
  companys: ICompanys;
  lang_config: TLangConfig;
  navigation: TNavigation;
  need_to_be_connect?: boolean;
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const path = usePathname();
  const lang = useLang() || "en";
  const [cm] = useState(companys.find((c) => c.name === path.split("/")[3]));
  const session = useSession();
  const router = useRouter();

  const [updateCompanyName] = useFncAction({
    fnc: (data: string) =>
      ChangecompanyAction({
        name: data,
      }),
    valideStatus: 200,
    onSucess: (data) => {
      console.log(data);
    },
    onError: (error) => {
      if (error?.status === 403) {
        toast.error("You are not an admin");
      }
    },
  });

  if ((lang as never) === "favicon.ico") return;
  return (
    <header>
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="-m-1.5 p-1.5 flex gap-5">
          <Link
            href={
              need_to_be_connect ? `/${lang}/c/${cm?.name}/app` : `/${lang}/`
            }
            className=""
          >
            <span className="sr-only">Your Company</span>
            <Image
              className="h-8 w-auto"
              src={LogoPic}
              width={32}
              height={32}
              alt=""
            />
          </Link>
          {/* <Button onClick={() => updateCompanyName("delice_id")}>TOTO</Button> */}
          {/* {session.status === "authenticated" &&
          session.data?.user.role === "admin" ? (
            <Select
              defaultValue={cm?.id}
              onValueChange={(v) => {
                updateCompanyName(companys.find((c) => c.id === v)?.name || "");
              }}
            >
              <SelectTrigger className="w-[270px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {companys.map((company) => (
                  <SelectItem value={company.id} key={company.id}>
                    {company.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ) : null} */}
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Menu className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {lang &&
            navigation[lang].map((item) => (
              <Link
                key={item.name}
                href={
                  need_to_be_connect
                    ? `/${lang}/c/${cm?.name}${item.href}`
                    : `/${lang}${item.href}`
                }
                className="text-sm font-semibold leading-6"
              >
                {item.name}
              </Link>
            ))}
          <div
            onClick={() => {
              if (session.status === "authenticated") {
                signOut({ callbackUrl: `/${lang}` });
              } else {
                console.log(session.status);
                router.push(`/${lang}/login`);
              }
            }}
            className="text-sm font-semibold leading-6 flex gap-1 cursor-pointer"
          >
            {session.status === "authenticated"
              ? lang_config[lang].logout
              : lang_config[lang].login}
            <span aria-hidden="true" className="">
              &rarr;
            </span>
          </div>
        </div>
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full bg-background overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1">
          <div className="flex items-center justify-between">
            <Link
              href={
                need_to_be_connect ? `/${lang}/c/${cm?.name}/app` : `/${lang}/`
              }
              className="-m-1.5 p-1.5"
            >
              <span className="sr-only">Saggesses</span>
              <Image
                className="h-8 w-auto"
                src={LogoPic}
                width={32}
                height={32}
                alt=""
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <X className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y">
              <div className="space-y-2 py-6">
                {navigation[lang].map((item) => (
                  <Link
                    key={item.name}
                    href={
                      need_to_be_connect
                        ? `/${lang}/c/${cm?.name}/app`
                        : `/${lang}/`
                    }
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-secondary"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-6">
                <Button
                  variant="secondary"
                  onClick={() => {
                    if (session.status === "authenticated") {
                      signOut({ callbackUrl: `/${lang}` });
                    } else {
                      console.log(session.status);
                      router.push(`/${lang}/login`);
                    }
                  }}
                  className="w-full bg-transparent"
                >
                  {session.status === "authenticated"
                    ? lang_config[lang].logout
                    : lang_config[lang].login}
                </Button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
