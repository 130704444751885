"use client";

// *** Module *** //

import React from "react";

// *** My Module *** //

// import { navigation } from "./_hec/navigation";
import useLang from "@/hooks/useLang";
import { TNavigation } from "./_hec/navigation";
import Link from "next/link";

export default function Footer({
  navigation,
  need_to_be_connect = true,
}: {
  navigation: TNavigation;
  need_to_be_connect?: boolean;
}) {
  const lang = useLang() || "en";

  if ((lang as never) === "favicon.ico") return;
  return (
    <footer>
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav
          className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
          aria-label="Footer"
        >
          {navigation[lang].map((item) => (
            <div key={item.name} className="pb-6">
              <Link
                href={
                  need_to_be_connect
                    ? `/${lang}/c${item.href}`
                    : `/${lang}${item.href}`
                }
                className="text-sm leading-6"
              >
                {item.name}
              </Link>
            </div>
          ))}
        </nav>
        <p className="mt-10 text-center text-xs leading-5">
          &copy; 2024 Sagesses, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
